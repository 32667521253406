import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Thumbnail from "app/components/Thumbnail";
import { H3 } from "app/components/Typography";
import ResponsiveVideoPlayer from "app/components/ResponsiveVideoPlayer";
import { userSelectsCategoryAction } from "modules/content";
import transition from "styled-transition-group";
import { useAccountMode } from "hooks/useAccountMode";
import FollowButton from "./FollowButton";
import { useFollowCategory } from "../hooks";

const FadeInWrapper = transition.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: ${({ opacity }) => opacity};

  &:enter {
    opacity: 0;
  }
  &:enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
`;

const FadeOutWrapper = transition.div`
  position: relative;
  opacity: ${({ opacity }) => opacity};

  &:enter {
    opacity: 0;
  }
  &:enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
`;

const Wrapper = styled(Flex)`
  mask-image: -webkit-radial-gradient(white, black);
`;

const CategoryCard = ({ category, history }) => {
  const dispatch = useDispatch();
  const {
    name,
    slug,
    previewThumbnailUrl,
    previewVideoUrl,
    isFollowing,
  } = category;
  const isAnonymous = useSelector(({ auth }) => auth.isAnonymous);
  const [isPreviewVideoPlaying, togglePreviewVideoPlaying] = useState(false);

  const { accountMode } = useAccountMode();
  const followCategoryMutation = useFollowCategory();

  const toggleFollowCategory = async e => {
    e.stopPropagation();

    await followCategoryMutation({
      variables: {
        slug,
        isFollowing: !isFollowing,
      },
    });

    return isAnonymous && history.push("/register");
  };

  const userSelectsCategory = () => {
    dispatch(
      userSelectsCategoryAction({
        component: "Categories",
        categoryData: category,
        accountMode,
      })
    );
  };

  return (
    <Wrapper
      cursor="pointer"
      position="relative"
      borderRadius="8px"
      overflow="hidden"
      onMouseEnter={() => togglePreviewVideoPlaying(true)}
      onMouseLeave={() => togglePreviewVideoPlaying(false)}
      onClick={userSelectsCategory}
    >
      <Div position="relative" bg="black">
        <FadeOutWrapper
          timeout={500}
          in={!isPreviewVideoPlaying}
          opacity={isPreviewVideoPlaying ? 0 : 1}
        >
          <Thumbnail
            placeholderSrc={
              `${previewThumbnailUrl}?blur=200&px=16&auto=format` || null
            }
            src={`${previewThumbnailUrl}?w=600` || null}
          />
        </FadeOutWrapper>
        <FadeInWrapper
          timeout={500}
          in={isPreviewVideoPlaying}
          opacity={isPreviewVideoPlaying ? 1 : 0}
        >
          <ResponsiveVideoPlayer
            playing={!!isPreviewVideoPlaying}
            playsinline
            controls={false}
            loop
            muted
            showLoader={false}
            style={{ zIndex: 1, borderRadius: "8px" }}
            backgroundColor="black"
            url={previewVideoUrl || null}
          />
        </FadeInWrapper>
      </Div>
      <Flex
        position="absolute"
        backgroundColor="black"
        height="20%"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="0 0 8px 8px"
        mt="45%"
        style={{ zIndex: 2 }}
      >
        <H3 color="white" mx={2}>
          {name}
        </H3>
        <FollowButton
          variant={isFollowing ? "followingDark" : "followDark"}
          width="102px"
          height="24px"
          py={2}
          mr={2}
          fontSize="10px"
          position="relative"
          onClick={toggleFollowCategory}
        >
          {isFollowing ? "Following" : "Follow"}
        </FollowButton>
      </Flex>
    </Wrapper>
  );
};

CategoryCard.defaultProps = {
  category: {},
};

CategoryCard.propTypes = {
  category: PropTypes.shape({}),
  history: PropTypes.shape({}).isRequired,
};

export default CategoryCard;
