import Button from "app/components/Button";
import Grid from "app/components/Grid";
import styled from "styled-components";
import { flexbox, grid, space } from "styled-system";

interface Props {
  gridGap?: string | number;
  gridTemplateColumns?: string[];
}

type Wrapper = Props &
  React.HTMLProps<HTMLButtonElement> &
  React.HTMLAttributes<HTMLButtonElement>;

export const AllClassesButton = styled(Button)`
  @media (hover: hover) {
    :hover {
      background: ${({ theme }) => theme.colors.black};
      opacity: 0.85;
      }
    }
  }
`;

export const Wrapper = styled(Grid).attrs<Wrapper>(
  ({ gridGap, gridTemplateColumns }) => ({
    width: "100%",
    gridGap: gridGap || "24px",
    gridTemplateColumns: gridTemplateColumns || [
      "repeat(1, 1fr)",
      "repeat(1, 1fr)",
      "repeat(2, 1fr)",
      "repeat(2, 1fr)",
      "repeat(3, 1fr)",
    ],
  })
)`
  ${flexbox}
  ${space}
  ${grid}
`;
