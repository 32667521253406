import followButtonVariants from "theme/components/followButtonVariants";
import { variant } from "styled-system";
import styled from "styled-components";
import Button from "../../../../components/Button/index";

const StyledButton = styled(Button).attrs({
  borderRadius: "3px",
  py: 0,
})``;

export default styled(StyledButton)(
  variant({
    variants: followButtonVariants,
  })
);
