import colors from "../colors";

export default {
  follow: {
    color: colors.black,
    borderRadius: "3px",
    background: "none",
    border: `2px solid ${colors.black}`,
    "@media (hover: hover)": {
      ":hover": {
        color: colors.white,
        background: colors.black,
      },
    },
  },
  following: {
    color: colors.white,
    borderRadius: "3px",
    background: "black",
    border: `2px solid ${colors.black}`,
    "@media (hover: hover)": {
      ":hover": {
        color: colors.white,
        background: colors.black,
      },
    },
  },
  followDark: {
    color: colors.white,
    borderRadius: "3px",
    background: "none",
    border: `1.5px solid ${colors.white}`,
    "@media (hover: hover)": {
      ":hover": {
        color: colors.black,
        background: colors.white,
      },
    },
  },
  followingDark: {
    color: colors.black,
    borderRadius: "3px",
    background: "white",
    border: `1.5px solid ${colors.black}`,
    "@media (hover: hover)": {
      ":hover": {
        color: colors.white,
        background: colors.black,
      },
    },
  },
};
