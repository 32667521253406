import LoaderCentered from "app/components/Loader/LoaderCentered";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { CategoryFilter, useGetCategoriesQuery } from "services/graphql";
import { libraryViewScreen, AccountMode } from "services/typewriter/segment";
import { LIBRARY_MODULE, ANALYTICS_LOCATION } from "constants/index";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import CategoryCard from "./CategoryCard";
import { Wrapper } from "./styles";
import { LibraryContainer } from "../LibraryContainer";

const Categories = () => {
  const history = useHistory();
  const { loading, data } = useGetCategoriesQuery({
    variables: {
      slugs: [],
      filters: [CategoryFilter.IsOnboarding],
    },
  });
  const { isFamilyModeOn } = useFamilyModeSetting();

  useEffect(() => {
    libraryViewScreen({
      screen_name: "Library - Categories",
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_categories,
      account_mode: isFamilyModeOn ? AccountMode.Family : AccountMode.Studio,
    });
  }, []);

  return (
    <>
      <Helmet title="All Categories | Online Dance Classes and Tutorials" />
      {loading && <LoaderCentered />}
      <LibraryContainer>
        <Wrapper>
          {!loading &&
            data?.categories?.map(category => (
              <CategoryCard category={category} history={history} />
            ))}
        </Wrapper>
      </LibraryContainer>
    </>
  );
};

export default Categories;
